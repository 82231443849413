import axios from "axios";
import qs from 'qs';
import { getAuthToken, resetAuthToken } from "src/utils/token";

const API_BASE_URL = "https://fasolit.dev.optimalsolutionshub.net/api";
const CancelToken = axios.CancelToken;


const buildUrl = (url) => {
    return `${API_BASE_URL}${url}`;
};

const getConfig = (canceling) => {
    const token = getAuthToken();
    const headers = token ? {Authorization: `Bearer ${token}`} : {};
    return {
        headers,
        cancelToken: new CancelToken((c => {
            if (canceling) {
                canceling(c);
            }
        }))
    };
};

// Add a response interceptor
axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error(error);
    if (error.response.status === 500) {
        // add global error handling
    }
    return Promise.reject(error);
});

export const isCancelled = (error) => {
    return axios.isCancel(error);
};

export const get = (url, data, cancelling) => {
    const config = getConfig(cancelling);
    config.params = data;
    return axios.create({paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
    }).get(buildUrl(url), config).catch(handleError);
};

export const post = (url, data, cancelling) => {
    return axios.post(buildUrl(url), data, getConfig(cancelling)).catch(handleError);
};

export const put = (url, data, cancelling) => {
    return axios.put(buildUrl(url), data, getConfig(cancelling)).catch(handleError);
};

export const patch = (url, data, canceling) => {
    return axios.patch(buildUrl(url), data, getConfig(canceling)).catch(handleError);
};

export const del = (url, canceling) => {
    return axios.delete(buildUrl(url), getConfig(canceling)).catch(handleError);
};

const handleError = (error) => {
    const status = error.status ? error.status : error.response && error.response.status;
    if (status === 401) {
        if (window.location.pathname !== '/auth/login') {
            resetAuthToken();
            window.location = '/auth/login';
        }
    } else if (status === 423) {
        resetAuthToken();
        window.location = '/errors/error-423';
    }

    return Promise.reject(error);
};


